<template>
  <div>
    <Table
      :body="{}"
      :title="'users_management'"
      :fields="fields"
      :url="url"
      :showSearch="true"
      :key="initTbale"
    >
      <template #cell(id)="data">
        <p  style="width:65px;" class="badge badge-light-primary"> U-{{ data.item.id }}</p>

      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at | formatDate }}
      </template>
      <template #cell(company_name)="data">
        <b-link
          v-if="
            data.item &&
            data.item.con_to_company &&
            data.item.con_to_company[0] &&
            data.item.con_to_company[0].company_id &&
            data.item.con_to_company[0].company.name
          "
          :to="'/company/' + data.item.con_to_company[0].company_id"
        >
          {{ data.item.con_to_company[0].company.name }}
        </b-link>
        <span v-else>-</span>
      </template>
      <template #cell(type)="data">
        <span v-if="data.item.con_to_company[0].company.role == 'debtor'" class="d-flex">
          <feather-icon
            icon="CodesandboxIcon"
            class="text-primary"
            height="21"
          ></feather-icon>
          Debtor
        </span>
        <span  v-else-if="data.item.con_to_company[0].company.role == 'seller'" class="d-flex">
          <feather-icon
            icon="CodesandboxIcon"
            class="text-secondary"
            height="21"
          ></feather-icon>
          Seller
        </span>
        <span  v-else-if="data.item.con_to_company[0].company.role == 'buyer'" class="d-flex">
          <feather-icon
            icon="CodesandboxIcon"
            class="text-secondary"
            height="21"
          ></feather-icon>
          Buyer
        </span>
      </template>
      <template #cell(custom)="data">



        <div style="display: flex;justify-content: center;align-items:center;">
          <b-button variant="outline-success" size="sm" class="button-space" @click="loginAsClientFromAdmin(data.item)" v-b-tooltip.hover :title="$t('login_as_client')">
            <feather-icon icon="UserIcon"></feather-icon>
          </b-button>
          <b-link  :to="'/user/' + data.item.id">
            <b-button variant="outline-primary" size="sm" class="button-space"  v-b-tooltip.hover :title="$t('edit')">
              <feather-icon icon="EditIcon"></feather-icon>
            </b-button>
          </b-link>

          <b-button
          v-b-tooltip.hover :title="$t('delete')"
          class="button-space"
            variant="outline-danger"
            size="sm"
            v-b-modal.approveModal
            @click="
              changeModalInfo('delete', 'Delete', 'Are you sure that you want to delete this User?', data.item.id)
            "
          >
            <feather-icon icon="TrashIcon"></feather-icon>
          </b-button>
        </div>

      </template>
      <template #cell(status)="data">
        <span v-if="data.value == 2" class="text-danger">{{ $t("deleted") }}</span>
        <span class="text-secondary" v-else-if="data.value == 1  && data.item.con_to_company && data.item.con_to_company[0].company.status != 1 && data.item.email_verified_at != null">{{$t("waiting_for_company_review")}}</span>
        <span class="text-success" v-else-if="data.value == 1 && data.item.email_verified_at != null" >{{
          $t("active")
        }}</span>
        <span v-else-if="data.item.email_verified_at == null">{{ $t("incomplete") }}</span>
        <span v-else-if="data.value == 0">{{ $t("inactive") }}</span>


      </template>
    </Table>
    <ApproveModal :textContent="modalText" :title="modalTitle" />
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BTr,
  BTh,
  BButton,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import CustomLoader from "@/components/Common/CustomLoader";
import Table from "@/components/Common/Table2.vue";
import ApproveModal from "@/components/Common/ApproveModal";
import i18n from "@/libs/i18n";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default {
  name: "UsersList",
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
    CustomLoader,
    Table,
    ApproveModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalText: null,
      modalTitle: null,
      options: null,
      title: i18n.t("users_management"),
      fields: [
        { label: "created_at", align: "start", key: "created_at", sortable: true },
        { label: "id", align: "start", key: "id", sortable: true, value: "id" },
        { label: "type", align: "start", key: "type" },
        // {
        //   label: "name",
        //   align: "start",
        //   key: "name",
        //   sortable: true,
        //   value: "name",
        // },
        {
          label: "full_name",
          align: "start",
          key: "full_name",
          sortable: true,
          value: "full_name",
        },
        {
          label: "email",
          align: "start",
          key: "email",
          sortable: true,
          value: "email",
        },
        {
          label: "company_name",
          key: "company_name",
          sortable: false,
          company_id: "company_id",
          customSlot: true,
          value: "company_name",
        },
        {
          label: "question",
          align: "start",
          key: "question",
          sortable: false,
          value: "question",
        },
        {
          label: "status",
          key: "status",
          customSlot: true,
          sortable: false,
          value: "status",
        },
        {
          text: "",
          customSlot: true,
          key: "custom",
          class: "text-right minWidth220",
          value: "custom",
        },
      ],
      items: [],
      user: null,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 12,
      status: [],
      isLoading: false,
      totalItems: 0,
      approveModalAction: null,
      url: "/user/admin/list",
      idToChange: "",
      initTbale: 'init'
    };
  },
  created() {
    this.getApiData();
  },
  computed: {
    rows() {
      return this.totalItems;
    },
  },
  methods: {
    callApproveModalAction() {
      this.deleteUser(this.idToChange);
    },
    changeModalInfo(action, title, text, id) {
      this.idToChange = id;
      if (action === 'delete') {
          this.modalText = text;
          this.modalTitle = title;
      }else {
        this.approveModalAction = action;
        this.modalText = text;
        this.modalTitle = title;
      }
    },
    deleteUser(id) {
        this.$http.post(`user/admin/delete/${id}`).then((res) => {
          if (res) {
            this.initTable = Math.random();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "User deleted successfully",
                icon: "SuccessIcon",
                variant: "success",
              },
            });
          }
        });
      if (this.$route.path !== "/users") {
        router.push('/users');
      }
    },
    loginAsClientFromAdmin(item){
        console.log(item);
      this.isLoading = true;
      this.items = [];
      this.$http
          .post("/loginFromAdmin", {
            email: item.email,
          })
          .then((response) => {
            if (response.data.status == 200) {
              // window.open('https://terminal.aequitex.com/loginFromAdmin/'+response.data.value.token, "_blank");
              window.open('https://terminal.aequitex.com/loginFromAdmin/'+response.data.value.token, "_blank");
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
          });

    },
    getApiData() {
      this.isLoading = true;
      this.items = [];
      this.$http
        .post("/user/admin/list?page=" + this.page, {
          status: this.status,
          options: null,
          filters: this.filters,
          search: this.search,
        })
        .then((response) => {
            console.log(response)
          if (response.data.status == 200) {
            this.items = response.data.value.data;
            this.totalItems = response.data.value.total;
            this.page = response.data.value.current_page;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

  },
};
</script>

<style scoped>
.button-space {
  margin-right: 5px;
}
</style>
